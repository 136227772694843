<template>
  <slot />
</template>

<script setup lang="ts">
const panels: Ref<{ [k: string]: boolean }> = ref({})

const registerPanel = (id: string, state: boolean) => {
  panels.value[id] = state
}

const togglePanel = (id: string, state: boolean) => {
  panels.value[id] = state
}

const toggleAllPanels = (state: boolean) => {
  Object.keys(panels.value).forEach((item) => {
    panels.value[item] = state
  })
}

provide('panels', panels)
provide('registerPanel', registerPanel)
provide('togglePanel', togglePanel)
provide('toggleAllPanels', toggleAllPanels)
</script>
